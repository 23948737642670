.nav-panel--jdnow .nav-panel__inner {
  display: block;
}

.jd-now__container {
  display: flex;
  max-width: 1600px;
  margin: 0 auto;
}

.jd-now__container img {
  display: block;
  width: 100%;
}

.jd-now__half {
  display: flex;
  flex: 1;
  width: 50%;
}

.jd-now__col {
  flex-direction: column;
  flex: 1;
}

.jd-now-spot,
.jd-now__image {
  display: block;
}

.jd-now-spot {
  padding: 4.5%;
}

.jd-now__image:hover {
  opacity: .7 !important;
}

.jdnow-footwear__container {
  max-width: 890px;
  display: flex;
  margin: .75rem auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #333333;
  padding: 0 1.25rem;
}

@media only screen and (min-width: 1025px) {
  .jdnow-footwear__container {
    max-width: 1580px
  }
}

.jdnow-footwear__text {
  font-size: 1.5rem;
  color: white;
  font-weight: 400;
}

.jdnow-footwear__text--bold {
  font-weight: 900;
}

.jdnow-footwear__image-container {
  filter: invert(100%);
  max-height: 100%;
}

.jdnow-footwear__image-container:hover {
  filter: invert(65%);
}

.jdnow-footwear__image {
  display: block;
  height: 100%;
  max-width: 3rem;
}

@media only screen and (min-width: 1025px) {
  .jdnow-footwear__image {
    max-width: 8rem;
  }
}

.jdnow-footwear__image--margin-bottom {
  margin-bottom: .4rem;
}
