.nav-promotions .section-header {
  margin: 0;
}

.nav-panel--collections .nav-promotions {
  max-width: 220px;
}

.nav-panel--collections .nav-promotion {
  padding-bottom: 100%;
}

.new-arrivals {
  display: flex;
  margin: 1rem 0;
}

.nav-links .new-arrival {
  margin: 0;
  font-weight: 600;
  max-width: 110px;
  text-transform: uppercase;
  white-space: normal;
}

.new-arrival + .new-arrival {
  margin-left: 1rem;
}

.new-arrival__img {
  background-color: #f3f3f3;
  margin-bottom: .5rem;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.new-arrival__img img {
  position: absolute;
  top: 0;
  left: 0;
}

.nav-panel--collections .nav-promotions {
  max-width: 243px;
}

.nav-panel--collections .nav-links > ul:last-of-type {
  border-right: 1px solid #e8e8e8;
}

.nav-panel .style-pick span {
  font-size: 12px;
}

.nav-panel .style-pick,
.nav-panel .style-pick:hover,
.nav-panel .style-pick:hover h4.section-header {
  color: #000;
  transition: all .3s;
}

@media screen and (min-width: 1366px) {
  .nav-panel .style-pick,
  .nav-panel .style-pick h4 {
    color: #505050;
  }
}

.nav-panel--collections .nav-promotions .section-header a:after {
  content: "";
  display: none;
}

@media screen and (min-width: 1440px) {
  .nav-panel--collections .nav-promotions {
    max-width: 325px;
  }
  .new-arrivals .new-arrival:first-of-type {
    margin-right: 25px;
  }
}
