.nav-panel {
  background-color: #fff;
  color: #1a1a1a;
  left: 0;
  margin: 0 auto;
  min-height: 400px;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  padding: 15px !important;
  position: absolute;
  right: 0;
  top: 50px;
  transition: visibility 0s linear, opacity .25s ease-in;
  visibility: hidden;
  width: 100%;
  z-index: 100;
}

.hovered .nav-panel {
  transition: none;
}

.level0.open .nav-panel,
.level0:focus .nav-panel {
  opacity: 1;
  visibility: visible;
}

.nav-panel img {
  display: block;
  width: 100%;
}

.nav-links a {
  color: #000;
  display: inline-block;
  font-size: 10px;
  margin-bottom: .5rem;
  white-space: nowrap;
}

.nav-links a:hover {
  color: #000;
}

@media screen and (min-width: 1366px) {
  .nav-links a {
    color: #505050;
    font-size: 12px;
  }
}

.nav-links .no-border {
  border: none;
}

.nav-promotions {
  align-self: center;
  display: block;
  max-width: 500px;
  flex: 1;
}

.nav-promotion {
  background-color: #f3f3f3;
  display: block;
  margin-bottom: 1.5rem;
  overflow: hidden;
  padding-bottom: 31%;
  position: relative;
  width: 100%;
}

.nav-promotion:last-of-type {
  margin-bottom: 0;
}

.nav-promotion__img {
  height: 100%;
  left: 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  width: 100%;
}

.large-screen-only {
  display: none;
}

@media screen and (min-width: 1600px) {
  .large-screen-only {
    display: block;
  }
}

.nav-links {
  flex: 1;
  display: flex;
}

.nav-links>ul {
  border-right: 1px solid #e8e8e8;
  flex: 1;
}

.nav-links>ul:last-of-type {
  border: 0;
}

.nav-panel__inner {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 1.5rem 1.5rem 4rem 1.5rem;
  width: 100%;
}

.section-break {
  margin-bottom: 1rem;
}

.section-header {
  border-bottom: 2px solid #1a1a1a;
  color: #1a1a1a;
  margin: 1rem 0;
  padding-bottom: .25rem;
  position: relative;
  text-transform: uppercase;
}

.section-header a {
  display: block;
}

.section-header a::after {
  content: "";
  font-size: 11px;
  font-weight: 400;
  line-height: 1.75;
  position: absolute;
  right: 0;
  text-transform: none;
}

@media screen and (min-width: 1366px) {
  .section-header a::after {
    content: "View all";
  }
}

.nav-links ul li.section-header:first-child {
  margin-top: 0;
}

.section-header,
.nav-panel .section-header a {
  font-size: 10px;
  font-weight: 600;
}

@media screen and (min-width: 1366px) {
  .section-header,
  .nav-panel .section-header a {
    font-size: 12px;
  }
}

.section-sub-header a {
  font-weight: 700;
  text-transform: uppercase;
}

.columns-2x {
  column-count: 2;
  max-width: 300px;
}

.columns-2x ul {
  margin: 0 !important;
}

.nav-promotions .section-header {
  border-bottom: 0;
}

.nav-open::after {
  position: fixed;
  opacity: 1;
}

.nav-panel .lazy {
  opacity: 0;
}

.nav-panel .lazy.loaded,
.nav-panel .lazy.lazy-loaded,
.nav-panel .lazy.loading {
  opacity: 1;
}

.space-above-header {
  margin-top: 4rem;
}
