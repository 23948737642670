/*
 MESH uses ID's in selectors (BAD IDEA), when we need to override those styles
 we need to match the ID selector to override those styles. These overrides
 should be kept to a minimum to avoid specificity issues.
*/

#dropNav ul {
  margin: 0;
  margin-right: 1.5rem;
  padding-right: 1.5rem;
}

#dropNav #nav-menu {
  margin-top: 1.25rem;
  padding-left: 2.3rem;
}

#dropNav #nav-menu .navigation-sale-item:not(.level0) {
  color: #fd931d;
}

#dropNav .maxWidth > ul > li > a {
  font-size: 12px;
}

@media screen and (min-width: 1130px) {
  #dropNav .maxWidth > ul > li > a {
    font-size: 14px;
  }

  #dropNav #nav-menu {
    padding-left: 3rem;
  }
}

@media screen and (min-width: 1366px) {
  #dropNav .maxWidth > ul > li > a {
    font-size: 15px;
  }
}

@media screen and (min-width: 1600px) {
  #dropNav .maxWidth > ul > li > a {
    font-size: 18px;
  }
}

#dropNav .maxWidth > ul:hover > li > a:not(.navigation-sale-item) {
  color: #bbb;
}

#dropNav .maxWidth > ul:hover > li:hover > a:not(.navigation-sale-item) {
  color: #fff;
}

#dropNav .maxWidth > ul > li.wChild > a:before {
  transition: visibility 0s linear, opacity .25s ease-in;
}

#dropNav .maxWidth >ul > li.wChild.open > a:before {
  visibility: visible;
  opacity: 1;
}

/* body::after {
  background-color: rgba(0, 0, 0, .75);
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity .25s ease-in;
} */

header {
  z-index: 10;
}

@media screen and (min-width: 1440px) {
  #dropNav .maxWidth {
    padding: 0;
  }

  #dropNav ul {
    margin-right: 2rem;
    padding-right: 2rem;
  }

}
